<template>
  <section class="sign-in-page">
    <div id="container-inside">
      <div id="circle-small"></div>
      <div id="circle-medium"></div>
      <div id="circle-large"></div>
      <div id="circle-xlarge"></div>
      <div id="circle-xxlarge"></div>
    </div>
    <div class="container p-0">
      <div class="row no-gutters">
        <div class="col-md-6 text-center">
          <div class="sign-in-detail text-white">
            <a class="sign-in-logo mb-2" href="#">
              <img src="@/assets/images/logo-full.png" class="img-fluid" alt="logo" />
            </a>
            <div class="swiper-area">
              <Swiper :options="options">
                <SwiperSlide class="item">
                  <img src="@/assets/images/login/1.png" class="img-fluid mb-1" alt="logo" />
                  <h4 class="mb-1 text-white">{{$t('auth.first.title')}}</h4>
                  <p>{{$t('auth.first.subTitle')}}</p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img src="@/assets/images/login/2.png" class="img-fluid mb-1" alt="logo" />
                  <h4 class="mb-1 text-white">{{$t('auth.second.title')}}</h4>
                  <p>{{$t('auth.second.subTitle')}}</p>
                </SwiperSlide>
                <SwiperSlide class="item">
                  <img src="@/assets/images/login/3.png" class="img-fluid mb-1" alt="logo" />
                  <h4 class="mb-1 text-white">{{$t('auth.third.title')}}</h4>
                  <p>{{$t('auth.third.subTitle')}}</p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
        <div class="col-sm-6 bg-white pt-5 pb-5">
          <div class="sign-in-from">
            <h1 class="mb-0">{{$t('label.activeAccount')}}</h1>
            <p>
              {{$t('label.activeAccountText')}}
            </p>
            <form class="mt-4" @submit.prevent="onSubmit()">
              <div class="form-group">
                <label class="form-label" for="email">{{ $t('label.email') }}</label>
                <input type="email" v-model="form.email" class="form-control mb-0" id="email" name="email" :placeholder="$t('label.emailPlaceholder')"
                  :class="{ 'is-invalid': v$.form.email.$dirty && !v$.form.email.$pending && v$.form.email.$invalid }" />
                <span v-if="v$.form.email.$dirty && !v$.form.email.$pending && v$.form.email.$invalid" class="invalid-feedback">{{ $t('messages.required') }}</span>
              </div>
              <div class="d-inline-block w-100">
                <button type="submit" class="btn btn-primary float-end">
                  <i v-show="commomData.btnLoader" class="fa fa-spinner fa-spin"></i>
                  {{$t('button.getSms')}}
                </button>
              </div>
              <div class="sign-info">
                <span class="dark-color d-inline-block line-height-2">{{ $t('span.hasAccount') }} ?
                  <router-link :to="{ name: 'auth1.sign-in' }">{{ $t('link.signIn') }}</router-link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Swiper from '../../../components/custom/slider/Swiper'
import SwiperSlide from '../../../components/custom/slider/SwiperSlide'
import { reSendEmail } from '@/api/auth'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters } from 'vuex'
import { commomData } from '@/utils/commonVmodel'
export default {
  name: 'vaildEmail',
  components: { Swiper, SwiperSlide },
  setup() {
    const v$ = useVuelidate()
    return { v$ }
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState'
    })
  },
  data() {
    return {
      commomData: commomData(),
      options: {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        autoplay: {
          delay: 3000
        },
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      form: {
        // email: 'creator.app-858588575@snapmail.cc'
        email: ''
      }
    }
  },
  validations() {
    return {
      form: {
        email: { required, email }
      }
    }
  },
  methods: {
    onSubmit() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      this.commomData.btnLoader = true
      reSendEmail(this.form)
        .then(res => {
          console.log(res)
          this.$swal.mixin().fire({
            icon: 'success',
            text: res.message
          })
          this.commomData.btnLoader = false
        })
        .catch(err => {
          this.commomData.btnLoader = false
          console.log(err)
          this.$swal.mixin().fire({
            icon: 'error',
            text: err.response.data.message
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-area {
  overflow-x: hidden;
}
</style>
